<template>
  <div v-if="permission.access">
    <div class="row">
      <div class="col-md-8">
        <b-card no-body>
          <div class="table-responsive">
            <table class="table">
              <thead>
              <tr>
                <th class="align-middle text-center">Sl</th>
                <th>Name</th>
                <th>Email</th>
                <th class="text-center" v-if="permission.edit">Action</th>
              </tr>
              </thead>
              <tbody v-if="teachers.data.teacher.length>0">
              <tr
                  v-for="(teacher, index) in teachers.data.teacher"
                  :key="index"
              >
                <td class="align-middle text-center">
                  {{ 0 + index }}
                </td>
                <td>
                  <p class="mb-0"><b>{{ teacher.name }} </b></p>
                </td>
                <td class="align-middle">
                  <p class="mb-0">
                    <b>{{ teacher.email   }} </b>
                  </p>
                </td>
                <td class="align-middle text-center">
                  <div class="btn-group">
                    <button
                        v-if="permission.edit"
                        class="btn btn-secondary btn-sm"
                        @click="edit(teacher.id)"
                    >
                      <feather-icon icon="EditIcon" />
                    </button>
                    <router-link
                        :to="'/teacher/' + teacher.id"
                        class="btn btn-primary btn-sm"
                    >
                      <feather-icon icon="EyeIcon" />
                    </router-link>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </b-card>
      </div>
      <div class="col-md-4" v-if="permission.create || form.id != ''">
        <b-card :title="(form.id == '' ? 'Create' : 'Update') + ' Teacher'">
          <b-card-text>
            <validation-observer ref="loginValidation">
              <b-form
                  class="auth-login-form mt-2"
                  @submit.prevent
                  enctype="multipart/form-data"
              >
                <div class="row mb-2">
                  <div class="col-md-12">
                    <b-form-group label="Teacher Name" label-for="name">
                      <validation-provider
                          #default="{ errors }"
                          name="Course Name"
                          rules="required"
                      >
                        <Input
                            name="name"
                            type="text"
                            v-model="form.name"
                            id="name"
                            placeholder="Enter Teacher Name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-12">
                    <b-form-group label="Teacher Email" label-for="slug">
                      <validation-provider
                          #default="{ errors }"
                          name="email"
                          rules="required"
                      >
                        <Input
                            name="email"
                            type="text"
                            v-model="form.email"
                            id="slug"
                            placeholder="Enter Teacher Email"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-12">
                    <b-form-group label="Teacher Password" label-for="slug">
                      <validation-provider
                          #default="{ errors }"
                          name="password"
                          rules="required"
                      >
                        <Input
                            name="password"
                            type="text"
                            v-model="form.password"
                            id="slug"
                            placeholder="Enter Teacher Password"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                </div>
                <b-button
                    type="submit"
                    class="float-right"
                    variant="primary"
                    @click="form.id =''?validationForm:update()"
                >
                  {{ form.id == "" ? "Create" : "Update" }}
                </b-button>
                <b-button
                    type="submit"
                    class="float-right mx-1"
                    variant="outline-primary"
                    @click="Close"
                >
                  Close
                </b-button>
              </b-form>
            </validation-observer>
          </b-card-text>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BFormTextarea,
  BFormCheckboxGroup,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
} from "bootstrap-vue";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import _ from "lodash";
export default {
  data() {
    return {
      form: {
        id: "",
        email: null,
        name: null,
        password: null,
      },
      teachers: '',
      permission: {
        access: false,
        create: false,
        edit: false,
        delete: false,
      },
    };
  },
  mounted() {
    this.Permission();
    this.getResults();
  },
  methods: {
    async getResults(page) {
      const teacher = await this.callApi(
          "get",
          "/app/course/teacher",
      );
      if(teacher){
        this.teachers = teacher
      }
    },

    Close() {
      this.form.id = "";
      this.form.email = null;
      this.form.name = null;
      this.form.password = null;
    },

    async edit(id) {
      const teacher = await this.callApi("get", "/app/course/teacher/" + id);
      if(teacher){
        this.form.id = teacher?.data?.teacher?.id;
        this.form.name = teacher?.data?.teacher?.name
        this.form.email = teacher?.data?.teacher?.email
        this.form.password = teacher?.data?.teacher?.password
      }
    },

    async update() {
      axios
          .put("/app/course/teacher/"+this.form.id,this.form)
          .then((res) => {
            this.s(res.data.message);
            this.getResults();
            this.Close();
          })
          .catch((e) => {
          });
    },

    async add() {
      axios
          .post("/app/course/teacher", this.form)
          .then((res) => {
            this.s(res.data.message);
            this.getResults();
            this.Close();
          })
          .catch((e) => {
          });
    },

    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.add();
        }
      });
    },

    // Select Data for Delete
    selectAll(event) {
      if (event.target.checked == false) {
        this.select.data = [];
      } else {
        this.select.data = [];
        this.courses.data.forEach((category) => {
          this.select.data.push(category.id);
        });
      }
    },

    SearchData: _.debounce(function () {
      this.search.page = 1;
      this.getResults();
    }, 1000),

    // Paginate Data Gate
    paginateChange() {
      this.search.page = 1;
      this.getResults();
    },

    async Permission() {
      const permissions = await this.callApi("post", "/app/permission");
      permissions.data.permissions.forEach((permission) => {
        if (permission.slug == "course.access") {
          this.permission.access = true;
        }

        if (permission.slug == "course.create") {
          this.permission.create = true;
        }

        if (permission.slug == "course.edit") {
          this.permission.edit = true;
        }

        if (permission.slug == "course.destroy") {
          this.permission.delete = true;
        }
      });
    },
  },

  components: {
    vSelect,
    BCard,
    BCardText,
    BLink,
    BTabs,
    BTab,
    BMedia,
    BFormFile,
    BFormTextarea,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
    BFormCheckboxGroup,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
  },
  computed: {
    categories() {
      return this.$store.getters.getSubCategory;
    },
  },
};
</script>

<style></style>
